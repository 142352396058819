import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import CardDeck from "react-bootstrap/CardDeck";

export default class Projects extends React.Component {
    constructor(props) {
        super(props);
        /*
            The JSON object this.state.projects is a list of objects. The format of these objects is:
                * title: string,
                *

        */
        this.state = {
            projects: [
                {
                    header: "",
                    title: "",
                    text: "",
                    download: new URL("https://test.com"),
                    github: new URL("https://test.com")
                }
            ]
        };
    }

    componentDidMount() {
        console.log(this.state.projects);
        console.log(this.state.projects[0]);
    }
    render() {
        // TODO populate cards from from state
        // TODO style in .css!
        // TODO background-color: #292b2c ?
        return (
            <div className="proj-card-container" style = {{"backgroundColor": "var(--dark)"}}>
            <Container>
                <Row>
                    <CardDeck>
                        <Card className="text-white text-white bg-dark mb-3 d-inline-block" style = {{"maxWidth": "20rem", "minWidth": "18rem", "margin-top": "2em"}}>
                            <Card.Header> Project #1 </Card.Header>
                            <Card.Body>
                                <Card.Title>
                                    asdf
                                </Card.Title>
                                <Card.Text>
                                    hello
                                </Card.Text>
                                <Card.Link href="/">Download</Card.Link>
                                <Card.Link href="/">Visit on Github</Card.Link>
                            </Card.Body>
                        </Card>
                    </CardDeck>
                </Row>
            </Container>
            </div>
        );
    }
}
