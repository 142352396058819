import React, { useRef } from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Projects from "./Projects.js";
import NotFound from "./NotFound.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';

const Header = React.forwardRef((props, ref) => {
  return <Navbar bg="dark" expand="lg" variant="dark" id="navbar" className="navig" ref = {ref}>
            <Navbar.Brand href="/"> DDz </Navbar.Brand>
            { /* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
            { /* <Navbar.Collapse id="basic-navbar-nav"> */ }
              <Nav className="mr-auto">
                { /* <Nav.Link href="/">Home</Nav.Link> */ }
                {/*<Nav.Link href="/stnd_notz">Notes</Nav.Link>
                <NavDropdown title="Projects" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/prog">Programming</NavDropdown.Item>
                  <NavDropdown.Item href="/music">Music</NavDropdown.Item>
                </NavDropdown>*/}
                <NavDropdown title="Links" id="basic-nav-dropdown">
                  <NavDropdown.Item className = "navbar-item" href="https://imagetoaudio.com">ImageToAudio</NavDropdown.Item>
                  <NavDropdown.Item href="/querty">Querty?</NavDropdown.Item>
                  <NavDropdown.Item href="https://chat.pewue.com">Secure Chat</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            { /* </Navbar.Collapse> */}
         </Navbar>
});

const StandardNotes = React.forwardRef((props, ref) => {
  return <iframe src={ "https://" + window.location.hostname + "/stnd_notz_nginx_" } title="notes" width={window.innerWidth}  // TODO the src should allow requests only from this host server's ip!
            height={window.innerHeight} ref = {ref} frameBorder="0"> </iframe>
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.header = React.createRef();
    this.stnd_notes = React.createRef();
  }

  componentDidMount() {
    this.resizeIframe();
    window.addEventListener('resize', this.resizeIframe);
  }

  resizeIframe = () => {
    // the standard notes and header refs should not be null
    if (this.stnd_notes && this.stnd_notes.current) {
                                  // = current height of the window - the height of the header - the bottom padding of the header
      this.stnd_notes.current.height = window.innerHeight - 8;
      // for some reason (window.innerHeight - this.header.current.offsetHeight) was too big
      this.stnd_notes.current.width = window.innerWidth;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeIframe);
  }

  render() {
    return (
      <div className="App">
      <Router>
          <Switch>
            <Route exact path = "/">
              <h3 className = "title"> Oh Hi </h3>
              <h3> how&#39;d you get here :O </h3>
              <img src={process.env.PUBLIC_URL + "/giphy.gif"} className = "gifsg"></img>
              <p className ="paragraph"> btw got some spare btc? :P </p>
              <p className ="paragraph"> 1DxASKJun58Ph6FKMdgREnj9yBs5Lj42Fv
                <br/> <br/>
                Or maybe eth?
                <br/>
                0xC0ffeeACDC16f798d1B0648c6cF9F6F88EFaFEdA
                <br/>
                (Or check <a href="/crypto"> /crypto </a> for more addresses)
              </p>

              <Header ref={this.header}/>

            </Route>
            <Route path = "/stnd_notz">
              <StandardNotes ref={this.stnd_notes} />
            </Route>
            <Route path = "/not_found">
              <NotFound/>
            </Route>
            <Route path = "/prog">
              <Projects/>
            </Route>
            <Redirect to = "/not_found"/>
          </Switch>
      </Router>
      </div>
    );
  }
}

export default App;
